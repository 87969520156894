import React from "react"

// Components
import Seo from "components/seo"
import Hero from "components/hero"

// Page Sections
import StarterKitForm from "./screens/visby-university-starterkit/form"

const StarterKit = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Visby Medical Starter Kit" />
      <StarterKitForm />
    </React.Fragment>
  )
}

export default StarterKit

export const Head = () => (
  <Seo
    title="Visby Medical Starter Kit"
    description="Visby Medical Starter Kit Sign Up."
    recaptcha
  />
)
